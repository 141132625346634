import api from "@/services/apiService"

class GameService {

  getChildAccounts(accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/child' + (accountId?(`/${accountId}`):''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateGameActivate(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_API_MAIN + 'game/game-activate', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  // getAccountSetting(accountId) {
  //   return new Promise((resolve, reject) => {
  //     api.get(process.env.VUE_APP_API_MAIN + 'game/setting/' + accountId)
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       reject(error)
  //     })
  //   })
  // }

  updateGameSetting(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_API_MAIN + 'game/setting', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * รายงาน แพ้-ชนะ สุทธิ
   */
  getGameWinLose(params) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_MAIN}game/win-lose/${params.startDate}/${params.endDate}`, {
        accountId: params.accountId,
        gameIds: params.gameIds
      }, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * รายการเล่น
   */
  getMemberHistory(params, perPage=100, offset=0) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_MAIN}game/member-history/${params.startDate}/${params.endDate}/${perPage}/${offset}`, {
        accountId: params.accountId,
        gameIds: params.gameIds
      }, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * เครดิตสมาชิก
   */
  getAccountCredits(parentAccountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'game/account-credit' + (parentAccountId?`/${parentAccountId}`:''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * เติมเครดิต
   */
  deposit(accountId, data) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_MAIN}game/deposit/${accountId}`, data, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ถอนเครดิต
   */
  withdraw(accountId, data) {
    return new Promise((resolve, reject) => {
      api.post(`${process.env.VUE_APP_API_MAIN}game/withdraw/${accountId}`, data, {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ประวัติรายการเครดิตของ Member
   */
  getMemberCreditHistory(params, accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(`${process.env.VUE_APP_API_MAIN}game/member-credit/${params.startDate}/${params.endDate}` + (accountId?`/${accountId}`:''), {silent: true})
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }
}

export default new GameService()
